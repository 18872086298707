<template>
  <v-form @submit.prevent="onLocalSubmit" ref="formRef" class="mr-5 ml-5 mt-5 mb-5">
    <v-row dense >
      <v-col cols="6">
        <v-select
          v-model="currentItem.nomeEquipamento"
          :items="nomeequipamentos"
          item-text="nome"
          item-value="id"
          label="Equipamento"
          outlined
          clearable
          :rules="requiredField"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <vuetify-money
          v-model="currentItem.valordiario"
          label="Valor Diário"
          outlined
          clearable
          v-money="currencyMask"
          :options="options"
        />
      </v-col>
      <v-col cols="3">
        <vuetify-money
          v-model="currentItem.valormensal"
          label="Valor Mensal"
          outlined
          clearable
          v-money="currencyMask"
          :options="options"
        />
      </v-col>
      </v-row>
      <v-row dense>
      <v-col cols="6">
        <v-text-field
          v-model="currentItem.inicioVigencia"
          type="date"
          outlined
          clearable
          :rules="requiredField"
        >
          <template v-slot:label>
            <span>Início da Vigência <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="currentItem.fimVigencia"
          type="date"
          label="Fim da Vigência"
          outlined
          clearable
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense class="mr-5 ml-5">
      <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
    </v-row>

    <v-row class="justify-end" dense >
      <v-col class="text-end">
        <ConfirmButton :loading="isSubmitting" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>


<script>
import UtilsFunc from '../../../../../service/utilsFunc';
import ConfirmButton from '../../../../ConfirmButton.vue';
import '../../../../../assets/css/styles.css';

import api from '../../../../../http';

const { withCRUDUtils } = UtilsFunc

export default {
  name: "NovoItensDoForncedor",
  props: {
    currentItem: Object,
    loading: Boolean,
    onSubmit: Function,
    idFornecedor: Number
  },
  data: () => withCRUDUtils({
    equipamentoFornecedor: [],
    nomeequipamentos: [],
    fornecedor: [],
    isLoadingEquipamentos: false,
    isLoadingFornecedor: false,
    requiredField: [e => (e && e !== "") || 'Obrigatório'],
    isSubmitting: false,
    options: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2
    },
    currencyMask: {
      decimal: ',',
      thousands: '.',
      precision: 2,
    },
    headers: [
        { text: "Editar", value: "edit", width: 50 },
        { text: "Id", value: "id", width: 90 },
        { text: "Equipamento", value: "nomeEquipamento.nome" },
        { text: "Valor Diário", value: "valordiario" },
        { text: "Valor Mensal", value: "valormensal" },
        { text: "Início Vigência", value: "inicioVigencia" },
        { text: "Fim Vigência", value: "fimVigencia" },
        { text: "Status", value: "ativo" },
      ],
  }),
  components: { ConfirmButton },
  methods: {
    async onLocalSubmit() {
      const isValidForm = await this.$refs.formRef.validate();
      if (!isValidForm) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }
      this.isSubmitting = true;
      const fields = { 
        ...this.currentItem,
        fornecedor: this.idFornecedor,        
      };
      try {
        await this.onSubmit(fields);
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado (pag.NovoItensFornecedor / onLocalSubmit). Erro: ${error}`);
      } finally {
          this.isSubmitting = false;
      }
    },

    async getEquipamentoForncedor() {
      const LOADING_NAME = "get:equipamentoFornecedor";
      this.setLoading(LOADING_NAME);
      const idForncedeor = this.currentItem.id
      // console.log(idForncedeor)
      try {
        const { data } = await api.get(`/equipamentos/equipamentos_fornecedores/`);
        this.equipamentoFornecedor = data.filter(item => item.fornecedor === idForncedeor);
        // console.log(this.equipamentoFornecedor)
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro  (pag.NovoItensFornecedor / getEquipamentoForncedor). ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    }, 
    async getNomeEquipamento() {
      this.isLoadingEquipamentos = true;
      try {
        const { data } = await api.get(`equipamentos/nomeequipamentos/`);
        this.nomeequipamentos = data.filter(item => item.ativo)
        // console.log('NomeEquipamento', this.nomeequipamentos)
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (L-76). ${error}`);
      } finally {
        this.isLoadingEquipamentos = false;
      }
    },
    async getFornecedor() {
      this.isLoadingFornecedor = true;
      try {
        const { data } = await api('fornecedores/fornecedores/')
        data.sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia));
        this.fornecedor = data.filter( item => item.ativo) 
        // console.log('Fornecedor --< ',this.fornecedor)
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (pag.NovoItensFornecedor / getFornecedor). ${error}`);
      } finally {
        this.isLoadingFornecedor = false;
      }
    },
    getNomeEquipamentoById(id) {
      const equipamento = this.nomeequipamentos.find(equip => equip.id === id);
      return equipamento ? equipamento.nome : 'Equipamento não encontrado';
    },
    formatDate(isoString) {
      if (!isoString) return '';
      const date = new Date(isoString);
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); 
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
  },
  mounted() {
    this.getEquipamentoForncedor();
    this.getNomeEquipamento();
    this.getFornecedor()
  },
}
</script>

