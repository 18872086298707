<template>
  <v-row justify="center">
    <v-col>
      <v-data-table :loading="isLoading('get:fornecedor')"
        loading-text="Carregando Informações...."
        :items="filteredDataFormatted" 
        :search="search"
        :headers="headers"
        :single-expand="singleExpand" 
        :expanded.sync="expanded" 
        show-expand
        class="fixed-header-table" fixed-header  height="700px"
        :items-per-page="-1" >
      
        <template v-slot:expanded-item="{ headers, item}">
          <td :colspan="headers.length" class="py-4 grey lighten-2 text-start"> 
            <FornecedorExpand :currentItemId="item.id" />
          </td>
        </template>
        <template v-slot:top>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-row>
                <v-col cols="5">
                  <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify"
                    clearable />
                </v-col>
                <v-col class="text-end">
                  <v-badge left bordered overlap :content="activeFilters.length">
                    <v-btn class="mr-4 elevation-0" text v-on:click="showFilters = !showFilters">
                      <v-icon left>
                        mdi-filter
                      </v-icon>
                      Filtros
                    </v-btn>
                  </v-badge>
                  <v-btn class="mr-4 elevation-0" text @click="collapseAll"> Recolher Linhas </v-btn>
                  <v-btn class="mr-4 elevation-0" text @click="atualizar">
                    <v-icon left>
                      mdi-reload
                    </v-icon>
                    atualizar
                  </v-btn>
                  <v-btn color="success" class="elevation-0" @click="dialog.create = true">
                    <v-icon left>
                      mdi-plus
                    </v-icon>
                    Novo
                  </v-btn>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="elevation-0 ml-5" @click="exportToExcel" v-bind="attrs" v-on="on" style="background-color: transparent;">
                        <v-icon color="green" size="28">mdi-file-excel</v-icon>
                      </v-btn>
                    </template>
                    <span>Exportar para Excel</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-expand-transition>
                <v-card outlined v-show="showFilters" dense>
                  <h4 class="ml-4 mt-4">Filtrar por</h4>
                  <v-row dense class="py-2 px-4">
                    <v-col cols="2">
                      <v-select hide-details :items="statusOptions" label="Status" item-text="label" item-value="value"
                        v-model="filters.is_active.value" outlined dense />
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-row dense>
                      <v-col class="text-end">
                        <v-btn color="error" text elevation="0" @click="clearFilters">
                          Limpar filtros
                        </v-btn>
                        <v-btn color="success" class="ml-2" elevation="0" @click="applyFilters">
                          Aplicar filtros
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
            <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
          </v-chip>
        </template>
        <template v-slot:[`item.data_contrato`]="{ item }">
            {{ item.data_contrato | formatDatabr }}
        </template >
        <template v-slot:[`item.editar`]="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                        <v-icon color="green">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Clique para editar o fornecedor</span>
            </v-tooltip>
        </template>
        <template v-slot:[`item.editarItens`]="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'updateItens')">
                        <v-icon color="green">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Clique para editar os itens do fornecedor</span>
            </v-tooltip>
        </template>
        <template v-slot:[`item.editartaxas`]="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'updateTaxas')">
                        <v-icon color="green">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Clique para editar as taxas do fornecedor</span>
            </v-tooltip>
        </template>
        <template v-slot:[`item.tel`]="{ item }">
          <v-tooltip top v-if="item.tel">
            <template v-slot:activator="{ on, attrs }">
              <a :href="`https://wa.me/${item.tel}`" target="_blank" class="whatsapp-link" v-bind="attrs" v-on="on">
                <v-icon color="green">mdi-whatsapp</v-icon>
                <span>{{ formatPhone(item.tel) }}</span>
              </a>
            </template>
            <span>Chamar no WhatsApp</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <v-tooltip top v-if="item.email">
            <template v-slot:activator="{ on, attrs }">
              <a :href="`mailto:${item.email}`" class="email-link" v-bind="attrs" v-on="on">
                <v-icon color="blue">mdi-email</v-icon>
                <span>{{ item.email }}</span>
              </a>
            </template>
            <span>Enviar Email</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.cnpj`]="{ item }">
          {{ formatCnpj(item.cnpj) }}
        </template>
        <template v-slot:[`item.criticidade`]="{ item }">
        {{ item.criticidade ? "Sim" : "Não" }}
        </template>
      </v-data-table>
    </v-col>
<!-- Novo Fornecedor -->
    <v-dialog v-model="dialog.create">
      <v-card>
        <v-card-title class="sticky-title title-border">
          Novo Fornecedor:
          <v-spacer></v-spacer>
          <v-btn icon @click="closeCreateDialog">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <Cadastro :currentItem="newItem" :loading="isLoading('post:fornecedor')" :onSubmit="postFornecedor" />
        </v-card-text>
      </v-card>
    </v-dialog>
<!-- Editar foencedor -->
    <FullscreenDialog v-model="dialog.update" max-width="80%" >
      <v-card  >
        <v-card-title class="sticky-title title-border">
          Editar Fornecedor: {{ selectedRow.nome_fantasia }} / ID: {{ selectedRow.id }}
          <v-spacer></v-spacer>
          <v-btn icon @click="closeUpdateDialog">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
          <Fornecedores :currentItem="selectedRow" :loading="isLoading('put:fornecedor')" :onSubmit="putOperator" class="ml-5 mr-5" />
       </v-card>
    </FullscreenDialog> 
<!-- Editar Itens do foencedor -->
    <FullscreenDialog v-model="dialog.updateItens" max-width="80%" >
      <v-card>
        <v-card-title class="sticky-title title-border">
          Editar Equipamentos do Fornecedor: {{ selectedRow.nome_fantasia }} / ID: {{ selectedRow.id }}
          <v-spacer></v-spacer>
          <v-btn icon @click="closeUpdateItensDialog">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
          <ItensFornecedor :currentItem="selectedRow" :loading="isLoading('put:itensfornecedor')" :onSubmit="putItensOperator" class="ml-5 mr-5" />
       </v-card>
    </FullscreenDialog> 
<!-- Editar Taxas do foencedor -->
    <FullscreenDialog v-model="dialog.updateTaxas" max-width="80%" >
      <v-card>
        <v-card-title class="sticky-title title-border">
          Editar Taxas do Fornecedor: {{ selectedRow.nome_fantasia }} / ID: {{ selectedRow.id }}
          <v-spacer></v-spacer>
          <v-btn icon @click="closeUpdateTaxasDialog">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
          <TaxaFornecedor :currentItem="selectedRow" :loading="isLoading('put:taxafornecedor')" :onSubmit="putTaxasOperator" class="ml-5 mr-5" />
       </v-card>
    </FullscreenDialog> 
  </v-row>
</template> 
<script>
import api from '../../http';
import UtilsFunc from '../../service/utilsFunc';
import FullscreenDialog from '../FullscreenDialog.vue';
import Fornecedores from './componentes/fornecedores/Fornecedores.vue';
import ItensFornecedor from './componentes/fornecedores/Equipamento/ItensFornecedor.vue';
import FornecedorExpand from '../cadastros/componentes/fornecedores/FornecedorExpand.vue';
import TaxaFornecedor from './componentes/fornecedores/Taxa/Taxas.vue';
import Cadastro from './componentes/fornecedores/Cadastro.vue';
import './../../assets/css/styles.css';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';



const { withCRUDUtils } = UtilsFunc

export default {
  name: 'Operdaoras',
  components: {
    FullscreenDialog,
    Fornecedores, 
    Cadastro,
    ItensFornecedor,
    FornecedorExpand,
    TaxaFornecedor,
  },
  data: () => withCRUDUtils({
    loading: false,
    search: '',
    currentTab: 0,
    newItem: { ativo: 1 },
    showFilters: false,
    singleExpand: false,
    expanded: [],
    statusOptions: [
      { label: "Ativo", value: true },
      { label: "Inativo", value: false },
    ],
    dialog: {
      create: false,
      update: false,
      updateItens: false,
      updateTaxas: false,
    },
    filters: {
      is_active: { value: true, compareType: 'equal', prop: 'ativo' },
    },
    activeFilters: [],
    headers: [
      { text: "Editar fornecedor", sortable: true, align: "start", value: "editar" },
      { text: "ID", sortable: true, value: "id" },
      { text: "Fornecedor", sortable: true, value: "nome_fantasia", width: 200 },
      { text: "Tipo de material", sortable: true, value: "tipo" },
      { text: "CNPJ", sortable: true, value: "cnpj", width: 170 },
      { text: "Telefone", sortable: true, value: "tel", width: 170 },
      { text: "Email", sortable: true, value: "email", width: 200 },
      { text: "Crítico", sortable: true, value: "criticidade", align: "center" },
      { text: "Data Contrato / Reajsute", sortable: true, value: "data_contrato", align: "center" },
      { text: "Editar Equipamentos", sortable: true, align: "start", value: "editarItens" },
      { text: "Editar Taxas", sortable: true, align: "start", value: "editartaxas" },
      { text: "Status", value: "ativo" },
    ],
  }),
  methods: {
    async getFornecedor() {
      const LOADING_NAME = 'get:fornecedor'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api('fornecedores/fornecedores/')
        data.sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia));
        // console.log('forne',data)
        this.tableData = data
        // console.log('Fornecedor --< ',this.tableData)
        this.applyFilters()
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (L-228). ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async postFornecedor(fields) {
      const LOADING_NAME = 'post:fornecedor'
      this.setLoading(LOADING_NAME)
      try {
        await api.post("fornecedores/fornecedores/", fields)
        await this.getFornecedor()
        this.dialog.create = false
        this.$toast.success("Fornecedor inserido com sucesso!")
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (L-243). ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async putOperator(fields) {
      const LOADING_NAME = 'put:fornecedor'
      this.setLoading(LOADING_NAME)
      try {
        await api.put(`fornecedores/fornecedores_put/${fields.id}/`, fields)
        await this.getFornecedor()
        this.dialog.update = false
        this.$toast.success("Fornecedor atualizado com sucesso!")
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (L-258). ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async putItensOperator(fields) {
      const LOADING_NAME = 'put:itensfornecedor'
      this.setLoading(LOADING_NAME)
      try {
        await api.put(`fornecedores/fornecedores_put/${fields.id}/`, fields)
        await this.getFornecedor()
        this.dialog.updateItens = false
        
        this.$toast.success("Itens atualizado com sucesso!")
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (L-275). ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async putTaxasOperator(fields) {
      const LOADING_NAME = 'put:taxafornecedor'
      this.setLoading(LOADING_NAME)
      try {
        await api.put(`fornecedores/fornecedores_put/${fields.id}/`, fields)
        await this.getFornecedor()
        this.dialog.updatetaxas = false
        this.$toast.success("Taxas atualizado com sucesso!")
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (putTaxasOperator). ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async applyFilters() {
      this.search = '';
      const filters = JSON.parse(JSON.stringify(this.filters))
      this.activeFilters = Object.values(filters).filter(filter => filter.value !== undefined);
    },
    clearFilters() {
      this.search = '';
      Object.keys(this.filters).forEach((prop) => this.filters[prop].value = '')
      this.filters = {
        is_active: { value: true, compareType: 'equal', prop: 'ativo' }
      }

      this.activeFilters = [{ value: true, compareType: 'equal', prop: 'ativo' }]
    },
    formatPhone(phone) {
      if (!phone) return '';
      const cleaned = ('' + phone).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }
      return phone;
    },
    formatCnpj(cnpj) {
      if (!cnpj) return '';
      const cleaned = ('' + cnpj).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
      if (match) {
        return `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`;
      }
      return cnpj;
    },
    atualizar(){
      this.search = '';
      this.clearFilters();
      this.getFornecedor();
      this.collapseAll()
    },
    closeCreateDialog() {
      this.dialog.create = false;
    },
    closeUpdateDialog() {
      this.dialog.update = false;
    },
    closeUpdateItensDialog() {
      this.dialog.updateItens = false;
    },
    closeUpdateTaxasDialog() { 
      this.dialog.updateTaxas = false; 
    },
    collapseAll() {
      this.expanded = [];
    },
    exportToExcel() {
      try {
        const worksheet = XLSX.utils.json_to_sheet(this.filteredDataFormatted);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Fornecedores');

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'fornecedores.xlsx');
      } catch (error) {
        console.error('Erro ao exportar para Excel:', error);
      }
    },
  },

  mounted() {
    this.getFornecedor()
  },
  computed: {
    filteredData() {
      const filterBy = UtilsFunc.createFilter(this.activeFilters)
      return this.tableData.filter(filterBy)
    },
    fornecedoresComTipos() {
      return this.tableData.map(fornecedor => {
        // Concatenar os nomes dos tipos, separados por vírgula
        const tiposConcatenados = fornecedor.tipo.map(t => t.nome).join(', ');
        return {
          ...fornecedor,
          tiposFormatados: tiposConcatenados // Adicionar propriedade com os tipos formatados
        };
      });
    },
    filteredDataFormatted() {
      return this.filteredData.map(fornecedor => ({
        ...fornecedor,
        tipo: fornecedor.tipo.map(t => t.nome).join(', ')
      }));
    }
  }
}
</script>  

<style scoped>
.table-container {
  max-height: 500px; /* Define a altura máxima para o contêiner da tabela */
  overflow-y: auto;  /* Permite a rolagem vertical */
}

.fixed-header-table thead th {
  background-color: white; /* Cor de fundo do cabeçalho para que ele permaneça visível */
  position: sticky;
  top: 0;
  z-index: 1;
}

</style>